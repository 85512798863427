<template>
    <div>
        <b-container align="left" fluid>
            <b-button variant="outline-dark" v-b-toggle:collapse-1>
                <span class="when-open">Skjul</span>
                <span class="when-closed">Vis </span>
                detaljer
            </b-button>

            <b-button-group class="mx-3">
                <b-button :disabled="startButton" @click="setStatus('started')" class="my-3" variant="success">
                    Start
                </b-button>
                <b-button :disabled="pauseButton" @click="setStatus('paused')" class="my-3"
                    variant="warning">Pause</b-button>
            </b-button-group>

            <b-button disabled class="my-3" variant="outline-dark">
                Pluk Status: {{ pickingStatusLang }}
            </b-button>

            <b-row>
                <b-col>
                    <b-collapse id="collapse-1" visible>
                        <b-form ref="form" @submit.prevent="handleSubmit">
                            <b-card title="Kunde info">
                                <b-card-sub-title class="mb-2"><b>Ordre nr.:</b> {{ orderNumber }}
                                </b-card-sub-title>
                                <b-card-sub-title class="mb-2"><b>Kommentar:</b>
                                    <span style="color: red"> {{ comments }} </span>
                                </b-card-sub-title>

                                <b-card-sub-title class="mb-2"><b>Ship til:</b> {{ shipTo.shipToName }}
                                </b-card-sub-title>
                                <b-card-sub-title class="mb-2"><b>Att.:</b> {{ shipTo.ShipToAttentionName }}
                                </b-card-sub-title>
                                <b-card-sub-title class="mb-2"><b>Adresse:</b> {{ shipTo.ShipToAddress1 }}
                                </b-card-sub-title>
                                <b-card-sub-title class="mb-2"><b>Post nr.:</b>{{ shipTo.ShipToCountryCode }}
                                    {{ shipTo.ShipToPostalCode }} {{ shipTo.ShipToCity }}
                                </b-card-sub-title>
                                <b-card-sub-title class="mb-2"><b>Lokation.:</b>{{ shipTo.ShipToLocationName }}
                                </b-card-sub-title>
                            </b-card>
                        </b-form>
                    </b-collapse>
                    <b-form-checkbox disabled="disabled" v-model="isPickingAllowed" inline>
                        Klar til pluk:
                    </b-form-checkbox>
                    <b-form-checkbox disabled="disabled" v-model="giftWrapping" inline>
                        Gave indpakning:
                    </b-form-checkbox>
                    <b-form-checkbox disabled="disabled" v-model="nameOnLabels" inline>
                        Navn på etiket:
                    </b-form-checkbox>
                    <b-form-checkbox disabled="disabled" v-model="includeGiftCards" inline>
                        Inkluder gavekort:
                    </b-form-checkbox>
                </b-col>

                <b-col v-if="orderStatus === 'packing' || orderStatus === 'finished'">
                    <b-collapse id="collapse-1" visible>
                        <b-card title="Pakning">
                            <b-card-sub-title class="mb-2">
                                Udfyld formularen og tryk på Marker som afdendt og gem knappen for at afslutte pakningen
                            </b-card-sub-title>
                            <finish-order-form :formData="finishForm" :finishButton="finishButton" :updateKey="updateKey"
                                v-on:on-setstatus="setStatus" :key="updateKey"></finish-order-form>
                        </b-card>
                    </b-collapse>
                </b-col>
            </b-row>

            <!-- <b-table sticky-header="1000px" striped hover class="my-4" :items="items" :fields="fields" -->
            <b-table striped hover class="my-4" :items="items" :fields="fields" :current-page="currentPage"
                :per-page="perPage" responsive="true" small :key="updateKey" :tbody-tr-class="rowClass"
                head-variant="light">
                <template #cell(imageUrl)="row">
                    <b-img-lazy thumbnail fluid blank-src blank-height="200px" blank-width="200px" :src="row.item.imageUrl">
                    </b-img-lazy>
                </template>

                <template #cell(sampak)="data">
                    <span v-if="data.item.rowType === 'parent'">
                        <b-badge variant="info">Sampak</b-badge>
                    </span>
                    <!-- <b-button-group style="float: right">
  
              <b-button v-if="data.item.rowType === 'parent'" :disabled="true" :variant="danger">
                Sampak1
              </b-button>
            </b-button-group> -->
                </template>

                <template #cell(actions)="data">
                    <b-button-group style="float: right">
                        <!-- not pickabel -->
                        <b-button v-if="data.item.btncolor === 'danger'"
                            :variant="data.item.disabled ? 'outline-danger' : 'danger'"
                            @click="pickProduct(data, 'backorder')"
                            :disabled="readMode || data.item.isMissingProduct || data.item.disabled">
                            <span v-if="data.item.btncolor === 'danger' && data.item.isPicked !== true">Ikke plukket {{
                                data.item.quantity
                            }} stk.
                            </span>
                            <!-- <span v-else>Ikke plukket {{ data.item.quantity }} stk.</span> -->
                        </b-button>

                        <!-- Request move from secondary location -->
                        <b-button v-if="data.item.btncolor === 'warning'"
                            :variant="data.item.disabled ? 'outline-warning' : 'warning'"
                            @click="pickProduct(data, 'move_req')"
                            :disabled="readMode || data.item.isMissingProduct || data.item.disabled">
                            <span v-if="data.item.btncolor === 'warning' && data.item.isPicked !== true">Bestil opfyldning
                                {{
                                    data.item.quantity
                                }} stk.
                            </span>
                            <!-- <span v-else>Tilføjelse bestilt {{ data.item.quantity }} stk.</span> -->
                        </b-button>

                        <!-- pickabel -->
                        <b-button v-if="data.item.btncolor === 'success'"
                            :variant="data.item.disabled ? 'outline-success' : 'success'"
                            @click="pickProduct(data, 'picked')"
                            :disabled="readMode || data.item.isMissingProduct || data.item.disabled">
                            <span v-if="data.item.btncolor === 'success'">Pluk {{ data.item.quantity }} stk.
                            </span>
                            <!-- <span v-else>Plukket {{ data.item.quantity }} stk.</span> -->
                        </b-button>

                        <!-- pick on secondary locations -->
                        <span v-if="data.item.btncolor === 'primary'">
                            <h6>
                                <b-badge variant="info">Pluk {{ data.item.quantity }} stk. på stykliste lokationerne, og pak
                                    op</b-badge>
                            </h6>
                        </span>
                    </b-button-group>
                </template>

                <template #cell(locations)="data">
                    <div v-for="(itm, index) in data.item.location_list" v-bind:key="index">
                        <span v-if="itm.quantity !== ''">{{ itm.name }} {{ itm.quantity }} stk.</span>
                        <span v-else>{{ itm.name }}</span>
                    </div>
                </template>
            </b-table>
            <b-spinner v-if="isLoading" style="position: fixed; left: 50%; top: 50%; width: 4rem; height: 4rem"></b-spinner>
        </b-container>
    </div>
</template>


<script>
import FinishOrderForm from "./FinishOrderForm.vue";
import apis from "../../apis/Apis";
import cookies from "js-cookie";

export default {
    components: {
        FinishOrderForm,
    },
    data() {
        return {
            items: [], //contains table data
            fields: [
                { key: "productNumber", label: "Produkt nr." },
                { key: "title", label: "Titel" },
                { key: "imageUrl", label: "Foto" },
                { key: "sampak", label: "Sampak" },
                { key: "actions", label: "" },
                { key: "locations", label: "Lokation" },
            ],
            finishForm: {
                isPartialDelivery: null,
                commentsPartialDelivery: "",
                packedDate: null,
                numberOfPackages: null,
                numberOfPallets: null,
                numberOfScantainers: null,
                parcelLocation: null,
            },
            // selectedLocation: "",
            domSessionId: "",
            // fullname: "",
            id: "",
            updateKey: 0,
            status: "",
            replenishProducts: "",
            orderStatus: "", //started, paused, pendingpick, packing, finished, failed
            pickingStatus: false,
            pickingStatusLang: "",
            orderNumber: "",
            isPickingAllowed: false,
            loation: [],
            shipTo: {
                shipToName: "",
                ShipToAttentionName: "",
                ShipToAddress1: "",
                ShipToPostalCode: "",
                ShipToCity: "",
                ShipToCountryCode: "",
                ShipToCountry: "",
                ShipToLocationName: "",
            },
            giftWrapping: false,
            nameOnLabels: false,
            includeGiftCards: false,
            comments: "",

            totalRows: 0,
            currentPage: 1,
            perPage: 1000,
            selectedRow: {},

            readMode: false,
            isLoading: null,
            //hidewhen
            startButton: true,
            pauseButton: true,
            finishButton: true,

            totalOrderlines: 0,
            orderLinesPicked: 0,
            orderLinesResered: 0,
            missingProductNumbers: 0,
        };
    },
    mounted: function () {
        // console.log("mounted")
        this.domSessionId = cookies.get("DomSessionId");
        this.id = localStorage.currentPickId;
        localStorage.currentPickAction = "";
        this.getPickingList();
    },

    beforeRouteLeave(to, from, next) {

        // console.log("beforeRouteLeave this.orderStatus 1", this.orderStatus);
        if (
            (this.orderStatus === "") || (this.orderStatus === "finished") || (this.orderStatus === "packing")) {
            next();
        } else {
            let msg = "";
            let status = "";
            if (this.orderStatus === "started") {
                msg = "Ordren bliver sat på pause når du forlader den"
                status = "paused"
            }
            if (this.replenishProducts > 0) {
                // msg = "Ordren sættes på pause indtil bestillinger på opfyldning er gennemført."
                status = "replenish"
            }
            if (msg != "") {
                this.$bvModal
                    .msgBoxConfirm(msg)
                    .then((value) => {
                        if (value === false) {
                            next(false);
                        } else {
                            this.setStatus(status);
                            next();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (status !== "") {
                this.setStatus(status);
                next();
            } else {
                next();
            }
        }
    },

    methods: {

        rowClass(item, type) {
            if (!item || type !== "row") return;
            // if (item.isPicked === true) return "table-success";
            // if (item.isMissingProduct === true) return "table-danger";
        },

        forceUpdate() {
            console.log("forceUpdate")
            this.updateKey += 1;
        },

        // update order status
        setStatus: function (orderStatus, formData) {

            console.log("setStatus", orderStatus);
            let cont = true;
            if (formData !== undefined) {
                if (!formData.isPartialDelivery === "1") {
                    this.$bvModal
                        .msgBoxConfirm("Er du sikker på ordren skal afsendes med forbehold")
                        .then((value) => {
                            if (value === false) {
                                cont = false;
                            } else {
                                cont = true;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
                cont = true;
            }
            if (cont) {
                // prevent the order to go to started status when still in replenish
                if (this.orderStatus === "replenish" && orderStatus === "started")
                    orderStatus = "replenish"
                this.updateStatus(orderStatus, formData, this.id, this.domSessionId);
            }
        },

        updateStatus: async function (pickingStatusp, formData, id, domSessionId) {

            console.log("updateStatus ", pickingStatusp)
            try {
                this.isLoading = true;
                const response = await apis.updateStatus(
                    pickingStatusp,
                    formData,
                    id,
                    domSessionId
                );
                if (response.data.response === "success") {
                    console.log("updateStatus pickingStatusp ", pickingStatusp);
                    this.getPickingList();
                    this.orderStatus = pickingStatusp;
                    this.isLoading = false;
                    if (pickingStatusp === "started") {
                        this.readMode = false;
                        this.forceUpdate();
                        this.setButtonStatus(this.orderStatus);
                    } else if (pickingStatusp === "paused") {
                        this.readMode = true;
                        this.forceUpdate();
                        this.setButtonStatus(this.orderStatus);
                    } else if (pickingStatusp === "replenish") {
                        this.readMode = false;
                        this.forceUpdate();
                        this.setButtonStatus(this.orderStatus);
                    } else if (pickingStatusp === "finished") {
                        this.forceUpdate();
                        this.readMode = true;
                        this.setButtonStatus(this.orderStatus);
                    }
                } else {
                    this.orderStatus = "failed";
                }
            } catch (error) {
                console.log(error);
            }
        },
        // end update order status

        // picking a product data in row from table. picStatus is string from @pickProduct(data, "string")
        pickProduct: function (data, pickStatus) {

            // console.log("pickProduct", data, pickStatus)
            if ((this.orderStatus === "started") || (this.orderStatus === "replenish")) {

                this.updateItem(data, this.id, this.domSessionId, pickStatus);

            } else {
                this.$bvModal.msgBoxOk(
                    "Ordren er ikke startet. Start ordren før du begynder at plukke varer",
                    { title: "Fejl" }
                );
                return;
            }


        },

        updateItem: async function (data, id, domSessionId, pickStatus) {
            console.log("updateItem", "data", data, "id", id, "domsessionId", domSessionId, "pickStatus", pickStatus);

            this.isLoading = true;

            try {
                const response = await apis.updateItem(
                    data,
                    id,
                    domSessionId,
                    pickStatus,
                );

                if (response.data.response === "success") {
                    if (pickStatus === "picked") {
                        this.items[data.index].disabled = true;
                        this.orderLinesPicked++;
                    } else if (pickStatus === "backorder") {
                        this.items[data.index].disabled = true;
                        this.orderLinesPicked++;
                    } else if (pickStatus === "move_req") {
                        this.items[data.index].disabled = true;
                        this.orderLinesResered++;
                    }
                } else if (response.data.response === "error") {
                    if (response.data.errorcode === "ERROR001") {
                        this.items[data.index].disabled = true;
                        this.orderLinesResered++;
                        this.$bvModal.msgBoxOk("Produktet er allerede bestilt opfyldt", {
                            title: "Produktet er bestilt",
                        });
                    } else if (response.data.errorcode === "ERROR002") {
                        this.$bvModal.msgBoxOk("Kunne ikke reservere produktet", {
                            title: "Fejl kunne ikke reservere ERROR002",
                        });
                    } else if (response.data.errorcode === "ERROR003") {
                        this.$bvModal.msgBoxOk("Mangler antal eller lokation", {
                            title: "Fejl kunne ikke reservere ERROR003",
                        });
                    } else if (response.data.errorcode === "ERROR004") {
                        this.$bvModal.msgBoxOk("Mangler status", {
                            title: "Fejl kunne ikke reservere ERROR004",
                        });
                    }

                } else {

                    this.$bvModal.msgBoxOk("Noget gik galt prøv igen", {
                        title: "Systemfejl",
                    });
                }

                this.isLoading = false;
                // eslint-disable-next-line no-debugger
                debugger

                console.log("updateItem this.totalOrderlines  ", this.totalOrderlines);
                console.log("updateItem this.orderLinesPicked   ", this.orderLinesPicked);
                console.log("updateItem this.orderLinesResered   ", this.orderLinesResered);

                const totalOrdered = parseInt(this.orderLinesPicked) + parseInt(this.orderLinesResered);

                if (this.totalOrderlines === this.orderLinesPicked) {
                    this.pickingStatus = "packing";
                    this.$bvModal;
                    this.$bvModal.msgBoxOk("Ordren er nu færdigplukket og skal pakkes", {
                        title: "Plukning færdig",
                    });

                    this.setStatus("packing");
                } else if (this.totalOrderlines === totalOrdered) {

                    this.$bvModal.msgBoxOk("Ordren sættes på pause indtil bestillinger på opfyldning er gennemført.  ", {
                        title: "Plukning pauset",
                    });
                    this.orderStatus = "replenish";
                    this.setStatus("replenish");
                }

            } catch (error) {
                console.log(error);
            }
        },
        //end picking a product

        // load the picking list
        getPickingList: async function () {

            // console.log("getPickingList")
            this.isLoading = true;
            try {
                const response = await apis.getPickingList(this.id, this.domSessionId);

                // eslint-disable-next-line no-debugger
                debugger
                this.totalOrderlines = response.data.products.length + response.data.pickedproducts.length
                this.orderLinesPicked = response.data.pickedproducts.length

                if (this.totalOrderlines === this.orderLinesPicked) {
                    this.pickingStatus = "packing";
                }
                // eslint-disable-next-line no-debugger
                debugger

                this.updateForm(response.data);

                this.items.length = 0;
                
                console.log("Non picked products")
                this.updateTable(response.data.products);
                
                this.pickedDivider();
                
                console.log("Picked products")
                this.updateTable(response.data.pickedproducts);
                this.isLoading = false;


            } catch (error) {
                console.log(error);
            }
        },

        updateForm: function (data) {
// eslint-disable-next-line no-debugger
debugger
            console.log("updateForm", data);

            this.comments = data.comments;
            this.giftWrapping = data.giftWrapping;
            this.isPickingAllowed = data.isPickingAllowed;
            this.nameOnLabels = data.nameOnLabels;
            this.orderNumber = data.orderNumber;
            this.shipTo.ShipToAddress1 = data.shipTo.ShipToAddress1;
            this.shipTo.ShipToAttentionName = data.shipTo.ShipToAttentionName;
            this.shipTo.ShipToCity = data.shipTo.ShipToCity;
            this.shipTo.ShipToCountry = data.shipTo.ShipToCountry;
            this.shipTo.ShipToCountryCode = data.shipTo.ShipToCountryCode;
            this.shipTo.ShipToPostalCode = data.shipTo.ShipToPostalCode;
            this.shipTo.shipToName = data.shipTo.shipToName;
            this.shipTo.ShipToLocationName = data.shipTo.ShipToLocationName;
            this.imageUrl = data.imageUrl;
            this.status = data.status;
            this.replenishProducts = data.replenishProducts

            //finishForm
            this.missingProductNumbers = data.missingProductNumbers;
            if (this.missingProductNumbers > 0) {
                this.finishForm.isPartialDelivery = "1";
            }
            // console.log(
            //   "updateform this.finishForm.isPartialDelivery",
            //   this.finishForm.isPartialDelivery
            // );

            this.finishForm.commentsPartialDelivery = data.commentsPartialDelivery;

            if (data.packedDate === "") {
                this.finishForm.packedDate = new Date();
            } else {
                this.finishForm.packedDate = data.packedDate;
            }

            this.finishForm.numberOfPackages = data.numberOfPackages;
            this.finishForm.numberOfPallets = data.numberOfPallets;
            this.finishForm.numberOfScantainers = data.numberOfScantainers;
            this.finishForm.parcelLocation = data.parcelLocation;

            // this.totalOrderlines = data.totalOrderlines;
            // this.orderLinesPicked = data.orderLinesPicked;
            // console.log("totalOrderlines", this.totalOrderlines)
            // console.log("orderLinesPicked", this.orderLinesPicked)

            // if (this.totalOrderlines === this.orderLinesPicked) {
            // if no morre products to pick
            if (data.products.length === 0) {
                if (data.orderStatus !== "finished") {
                    this.orderStatus = "packing";
                    this.setButtonStatus(this.orderStatus);
                    this.forceUpdate();
                } else {
                    this.orderStatus = "finished";
                    this.setButtonStatus(this.orderStatus);
                    this.forceUpdate();
                    this.readMode = true;
                }
            } else {
                if (data.orderStatus === "") {
                    this.readMode = true;
                    this.setButtonStatus(this.orderStatus);
                } else if (data.orderStatus === "started") {
                    this.orderStatus = data.orderStatus;
                    this.setButtonStatus(this.orderStatus);
                    this.readMode = false;
                } else if (data.orderStatus === "replenish") {
                    this.orderStatus = data.orderStatus;
                    this.setButtonStatus(this.orderStatus);
                    this.readMode = true;
                } else {
                    this.readMode = true;
                    this.setButtonStatus(this.orderStatus);
                }
            }
        },


        updateTable: function (items) {
            console.log("updateTable", items);

            items.forEach(this.evalOrderLine);

            //console.log("updateTable items", Object.entries(this.items));
            // Object.entries(this.items).forEach(keyValuePair => {console.log("  ",...keyValuePair)})
        },


        evalOrderLine: function (orderline) {
            // console.log("evalOrderLine", orderline);

            let thisLocation = null;
            let thisLocationStockQ = 0;
            let thisDefaultLocation = null;

            let thisBoms = null;
            let btncolor = "success";
            let retVal = {};
            // let hasStock = false;
            let thisDisabled = false;
            let rowLine = {};

            if (orderline.productNumber === "FUN-30_AFRY20300") {
                // eslint-disable-next-line no-debugger
                debugger
            }

            btncolor = "success"; //grøn

            if (orderline.isParentPickable) {
                btncolor = "success";
            }

            if (orderline.isChildsPickable) {
                btncolor = ""; //ingen farve på parent produkt
            }

            if (orderline.pickingType === "move_req") {
                btncolor = "warning"; //gul
            }

            //evaluate stock on primary location
            if (orderline.Locations.length !== 0) {
                thisLocation = orderline.Locations;
                thisDefaultLocation = orderline.Locations[0].name;
                thisLocationStockQ = orderline.Locations[0].quantity;
            }

            // console.log("thisLocationStockQ", thisLocationStockQ)


            if (!orderline.isParentPickable & !orderline.isChildsPickable) {
                btncolor = "danger"; //ingen pluk
            }

            if (orderline.isReplenisable & (thisLocationStockQ < orderline.quantity)) {
                btncolor = "warning"; //gul
            }

            //if product is picked
            if (orderline.pickingType !== "") {
                thisDisabled = true;
                if (orderline.pickingType === "picked") {
                    btncolor = "success"; //grøn
                } else if (orderline.pickingType === "move_req") {
                    btncolor = "warning"; //gul
                } else if (orderline.pickingType === "missing") {
                    btncolor = "danger"; //rød
                }
            }

            //if any boms line exists. 1. evaluate buttoncolor.
            if (orderline.boms !== undefined) {
                if (orderline.boms.length !== 0) {
                    retVal = this.evalBomsLines(orderline, btncolor);
                    thisBoms = retVal.thisBoms;
                }
            }

            // if sampak and no location on main location, but on separate primary bom's locations we make it possible to pick on child
            if (!orderline.isParentPickable & orderline.isChildsPickable) {
                btncolor = "primary";
                thisDisabled = true;
            }

            // if (!orderline.isParentPickable & !orderline.isChildsPickable) {
            //     btncolor = "danger"; //ingen pluk
            // }

            // if (orderline.isReplenisable & (thisLocationStockQ < orderline.quantity)) {
            //     btncolor = "warning"; //gul
            // }

            rowLine.productNumber = orderline.productNumber;
            rowLine.title = orderline.title;
            rowLine.quantity = orderline.quantity;
            rowLine.imageUrl = orderline.imageUrl;
            rowLine.location_list = thisLocation;
            rowLine.defaultLocation = thisDefaultLocation;
            rowLine.defaultLocQuantity = orderline.Locations[0].quantity;
            rowLine.rowType = orderline.rowType;
            rowLine.btncolor = btncolor;
            rowLine.disabled = thisDisabled;

            if (orderline.isPickable !== undefined) {
                rowLine.isPickable = orderline.isParentPickable
            }

            this.items.push(rowLine);
            // console.log("rowLine", JSON.stringify(rowLine, null, 2))

            if (btncolor !== "") this.totalRows++;

            if (thisBoms !== null) {
                rowLine._cellVariants = { productNumber: "secondary" };
                thisBoms.forEach((boms) => {
                    this.items.push(boms);
                    // console.log("boms", JSON.stringify(boms, null, 2))
                });
            }
            rowLine = null;

        },

        evalBomsLines: function (orderline) {
            let thisBoms = [];
            let thisRetval = {};
            let btncolor = "";

            thisRetval.hasStock = null; //until primary location is below ordered
            thisRetval.isBomsPicked = null; //flag that indicate that at least one entry has been picked on boms

            orderline.boms.forEach((bomline) => {

                let thisLocation = null;
                let thisDefaultLocation = null;
                let thisPickingStatus = false;
                let thisMissingProduct = false;
                let thisDisabled = false;
                let thisTotalBomLines = orderline.boms.length

                let parentProductNumber = orderline.productNumber

                if (bomline.productNumber === "FIS-1024270") {
                    // eslint-disable-next-line no-debugger
                    debugger;
                }
                if (bomline.Locations.length !== 0) {
                    thisLocation = bomline.Locations;
                    thisDefaultLocation = bomline.Locations[0].name;

                    // evaluate if all bomlines is picked. isBomsPicked is false if only one is not
                    if (thisRetval.isBomsPicked !== false) {
                        thisRetval.isBomsPicked = bomline.pickingType !== ""; //flag that indicate the entry is picked on boms
                    }

                    if (orderline.isChildsPickable) {
                        if (bomline.isPickable) {
                            btncolor = "success"; //ingen farve på parent produkt
                        }
                    }

                    if (bomline.isParentPickable) {
                        btncolor = "";  //Ingen pluk knapper på child. pluk på parent
                    }

                    if (!bomline.isParentPickable & !bomline.isPickable) {
                        btncolor = ""; //Ingen pluk knapper på parent & child.
                    }

                    // if (bomline.pickingType === "move_req") {
                    //   btncolor = "warning"; //gul
                    // }

                    thisPickingStatus = false;
                    if (bomline.pickingType !== "") {
                        thisDisabled = true;
                        if (bomline.pickingType === "picked") {
                            thisPickingStatus = true;
                            btncolor = "success"; //grøn
                        } else if (bomline.pickingType === "move_req") {
                            thisPickingStatus = true;
                            btncolor = "warning"; //gul
                        } else if (bomline.pickingType === "missing") {
                            thisMissingProduct = true;
                            btncolor = "danger"; //rød
                        }
                    }
                }

                //store the bomline 
                let thisBom = {
                    productNumber: bomline.productNumber,
                    title: bomline.title,
                    quantity: bomline.quantity,
                    _cellVariants: { productNumber: "secondary" },
                    location_list: thisLocation,
                    defaultLocation: thisDefaultLocation,
                    defaultLocQuantity: bomline.Locations[0].quantity,
                    isPicked: thisPickingStatus,
                    isMissingProduct: thisMissingProduct,
                    // pickOn: bomline.pickOn,
                    rowType: bomline.rowType,
                    disabled: thisDisabled,
                    btncolor: btncolor,
                    isPickable: bomline.isPickable,
                    parentProductNumber: parentProductNumber,
                    totalBomLines: thisTotalBomLines,
                };


                thisBoms.push(thisBom);
            })


            // return bomLines;
            thisRetval.thisBoms = thisBoms;
            return thisRetval;
        },

        pickedDivider: function () {

            let rowLine = {
                productNumber: "Behandlede produkter",
                _rowVariant: 'success'
            };

            this.items.push(rowLine);
            // console.log("rowLine", JSON.stringify(rowLine, null, 2))
        },

        //end load the picking list

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            return valid;
        },

        setButtonStatus: function (pickingStatusp) {

            console.log("setButtonStatus pickingStatusp", pickingStatusp);
            if (pickingStatusp === "started") {
                this.startButton = true;
                this.pauseButton = false;
                this.finishButton = true;
            } else if (pickingStatusp === "paused") {
                this.startButton = false;
                this.pauseButton = true;
                this.finishButton = true;
            } else if (pickingStatusp === "replenish") {
                this.startButton = true;
                this.pauseButton = false;
                this.finishButton = true;
            } else if (pickingStatusp === "packing") {
                this.startButton = true;
                this.pauseButton = true;
                this.finishButton = false;
            } else if (pickingStatusp === "finished") {
                this.startButton = true;
                this.pauseButton = true;
                this.finishButton = true;
            } else {
                this.startButton = false;
                this.pauseButton = true;
                this.finishButton = true;
            }
            this.setPickingStatusLang(pickingStatusp);
        },
        setPickingStatusLang: function (status) {
            if (status === "started") {
                this.pickingStatusLang = "Startet";
                return;
            }

            if (status === "paused") {
                this.pickingStatusLang = "Pauset";
                return;
            }
            if (status === "replenish") {
                this.pickingStatusLang = "Afventer genopfyldning";
                return;
            }
            if (status === "packing") {
                this.pickingStatusLang = "Afventer pakning";
                return;
            }
            if (status === "finished") {
                this.pickingStatusLang = "Afsluttet";
                return;
            }
        },
    },

};
</script>


<style scoped>
.collapsed>.when-open,
.not-collapsed>.when-closed {
    display: none;
}
</style>