<template>
  <div>
    <b-container class="my-3" align="left" fluid>
      <!-- <b-collapse id="collapse-1" visible> -->
      <b-form ref="form" @submit.prevent="handleSubmit" @reset="onReset">
        <b-form-group  :key="updateKey" :hidden="form.isPartialDelivery !== '1'">
          <b-form-checkbox
            v-model="form.isPartialDelivery"
            name="check-options"
            value="1"
            unchecked-value=""
            :disabled="true"
            >Ordren bliver afsendt med forbehold</b-form-checkbox
          >
        </b-form-group>

        <b-form-group
          label="Kommentar for mangler:"
          label-for="comment-input"
          invalid-feedback="Kommentar skal udfyldes"
          label-class="font-weight-bold"
          class="mb-1"
          :hidden="form.isPartialDelivery !== '1'"
        >
          <b-form-input
            placeholder="Skriv en kommentar hvis ordren er afsendt med forbehold"
            v-model="form.commentsPartialDelivery"
            :state="commentsPartialDeliveryState"
            :required="form.isPartialDelivery === '1'"
            :disabled="finishButton"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Dato for pakning:"
          label-for="date-packing-input"
          invalid-feedback="Dato for pakning skal udfyldes"
          label-class="font-weight-bold"
          class="mb-1"
        >
          <b-form-datepicker
            id="date-packing-input"
            type="date"
            placeholder=""
            v-model="form.packedDate"
            :state="packedDateState"
            required
            :disabled="finishButton"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group
          label="Antal pakker:"
          label-for="numbers-of-packing-input"
          invalid-feedback="Antal pakker skal udfyldes"
          label-class="font-weight-bold"
          class="mb-1"
        >
          <b-form-input
            type="number"
            placeholder=""
            v-model="form.numberOfPackages"
            :state="numberOfPackageState"
            required
            :disabled="finishButton"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Antal paller:"
          label-for="numbers-of-pallets-input"
          invalid-feedback="Antal paller skal udfyldes"
          label-class="font-weight-bold"
          class="mb-1"
        >
          <b-form-input
            type="number"
            placeholder=""
            v-model="form.numberOfPallets"
            :state="numberOfPalletsState"
            required
            :disabled="finishButton"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Antal scantainere:"
          label-for="numbers-of-scantainers-input"
          invalid-feedback="Antal scantainere skal udfyldes"
          label-class="font-weight-bold"
          class="mb-1"
        >
          <b-form-input
            type="number"
            placeholder=""
            v-model="form.numberOfScantainers"
            :state="numberOfScantainersState"
            required
            :disabled="finishButton"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Lokation:"
          label-for="parcel-Location-input"
          invalid-feedback="Lokation skal udfyldes"
          label-class="font-weight-bold"
          class="mb-1"
        >
          <b-form-input
            type="text"
            placeholder=""
            v-model="form.parcelLocation"
            :state="parcelLocation"
            required
            :disabled="finishButton"
          ></b-form-input>
        </b-form-group>

        <b-button
          :disabled="finishButton"
          type="submit"
          class="my-3"
          variant="danger"
          >Marker som afsendt og gem</b-button
        >
      </b-form>
      <!-- </b-collapse> -->
    </b-container>
  </div>
</template>

<script>
export default {
  mounted: function () {
    
  },
  props: ["formData", "finishButton", "updateKey"],
  emits: ["on-setstatus"],
  data() {
    return {
      form: {
        isPartialDelivery: this.formData.isPartialDelivery,
        commentsPartialDelivery: this.formData.commentsPartialDelivery,
        packedDate: this.formData.packedDate,
        numberOfPackages: this.formData.numberOfPackages,
        numberOfPallets: this.formData.numberOfPallets,
        numberOfScantainers: this.formData.numberOfScantainers,
        parcelLocation: this.formData.parcelLocation,
      },
      // form validation states
      isPartialDeliveryState: null,
      packedDateState: null,
      commentsPartialDeliveryState: null,
      numberOfPackageState: null,
      numberOfPalletsState: null,
      numberOfScantainersState: null,
      parcelLocation: null,

      //options
      isPartialDeliveryOptions: [
        { text: "Ja", value: "1" },
        { text: "Nej", value: "" },
      ],

      // probs
      // global
      domSessionId: "",
      fullname: "",
      username: "",
    };
  },

  methods: {
    handleSubmit() {
      //   Exit when the form isn't valid
      if (!this.checkFormValidity()) {

        return;
      }

      this.onSetStatus();
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.isPartialDeliveryState = valid;
      this.commentsPartialDeliveryState = valid;
      this.packedDateState = valid;
      this.numberOfPackageState = valid;
      this.numberOfPalletsState = valid;
      this.numberOfScantainersState = valid;
      this.parcelLocation = valid;

      return valid;
    },

    onSetStatus: function () {
      this.$emit("on-setstatus", "finished", this.form);
    },

    onReset() {},
  },
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>