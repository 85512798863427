<template>
  <div class="my-3">
    <h1>Login two factor</h1>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col lg="4">
          <b-alert
            v-model="showPincodeFailure"
            variant="danger"
            fade
            dismissible
          >
            Der var fejl i pinkode
          </b-alert>
          <b-form-group
            id="input-group-1"
            label="Pinkode:"
            label-for="input-1"
            description="Indtast pinkode"
            invalid-feedback="Pinkode skal udfyldes"
          >
            <b-form-input
              id="input-1"
              v-model="form.pinkode"
              type="text"
              placeholder="Indtast din pinkode"
              :state="pinkodeState"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="2"></b-col>
        <b-col>
          <b-button id="login" type="submit" class="my-3" variant="success"
            >Login</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import apis from "../../apis/Apis.js";
import constants from "../../constants.js";

export default {
  data() {
    return {
      showPincodeFailure: false,
      shopType: constants.shopType,
      pinkodeState: null,
      form: {
        pinkode: "",
      },
    };
  },

  methods: {
    handleSubmit() {
      //   Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.validatePincode();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.pinkodeState = valid;
      return valid;
    },
    validatePincode: async function () {
      this.isLoading = true;

      try {
        const response = await apis.validatePincode(
          this.form.pinkode,
          this.shopType
        );

        this.handleRespose(response);

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    handleRespose(res) {
      console.log("handleRespose ", res.data);
      if (res.data.errorCode > 0) {
        this.showPincodeFailure = true;
        console.log("Fejl i pinkode");
      } else {
        // Redirect to page
        this.$router.push("Orders");
      }
    },
  },
};
</script>

<style>
</style>